@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideIn {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.mobile-slide-in {
  animation: slideIn 0.6s ease-out;
}

@media (min-width: 1024px) {
  /* Para escritorio */
  .mobile-slide-in {
      animation: none; /* Desactivar animación en pantallas grandes */
  }
}


@font-face {
  font-family: 'Now';
  src: url('/public/Tipografia/Now//Now-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Now-Bold';
  src: url('/public/Tipografia/Now//Now-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Now-Thin';
  src: url('/public/Tipografia/Now//Now-Thin.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
