.sucursales-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
    gap: 20px;
}

.sucursal-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    min-width: 100px;
}

.sucursal-icon {
    font-size: 24px;
    color: #91ff00;
}

.sucursal-name {
    margin-top: 5px;
    font-weight: bold;
    text-align: center;
}